.add-button {
  background-color: inherit;
  border-radius: 7px;
  font-size: 15px;
  color: rgb(54, 54, 54);
  text-transform: capitalize;
  border: 0.5px solid rgb(55, 55, 55);
  height: fit-content;
  padding: 10px 20px;
}

.add-button:hover {
  border: 0.9px solid rgb(55, 55, 55);
  cursor: pointer;
  background-color: rgb(242, 242, 242);
}