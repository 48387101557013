.date-range-picker-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .react-aria-DateRangePicker {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--text-color);
  }
  
  .react-aria-Group {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    padding: 8px 0px;
    padding-right: 10px;
    /* border: 0.5px solid #040404; */
    border-radius: 4px;
    background: #fff;
    white-space: nowrap;
  }
  
  .react-aria-Group[data-focus-within] {
    outline-offset: -1px;
  }
  
  .react-aria-Label {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 4px;
  }
  
  .react-aria-Text[slot="description"] {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .react-aria-FieldError {
    font-size: 12px;
    color: #d32f2f;
  }
  
  [slot=start] + span {
    padding: 0 8px;
  }
  
  [slot=end] {
    margin-right: 2rem;
    flex: 1;
  }
  
  .react-aria-Button {
    background: #ffffff;
    color: #fff;
    border-radius: 4px;
    border: none;
    margin-left: auto;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .react-aria-Button:hover {
    background: #e1e1e1;
  }
  
  .react-aria-DateInput {
    width: unset;
    min-width: unset;
    padding: unset;
    border: unset;
    outline: unset;
  }

  /*  calendar styles  */
  
  .react-aria-Popover[data-trigger=DateRangePicker] {
    max-width: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  
  /* Calendar styles */
  .react-aria-RangeCalendar {
    background-color: #ffffff;
    padding: 16px;
  }
  
  .react-aria-CalendarCell {
    text-align: center;
    padding: 8px;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    z-index: 1;
  }
  
  .react-aria-CalendarCell[data-selected] {
    background: #1976d2;
    color: #fff;
    border-radius: 4px;
  }
  
  .react-aria-CalendarCell[data-selection-start] {
    background: #1976d2 !important;
    color: white !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    font-weight: 600;
  }
  
  .react-aria-CalendarCell[data-selection-end] {
    background: #1976d2 !important;
    color: white !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    font-weight: 600;
  }
  
  .react-aria-CalendarCell[data-selection-middle] {
    background: #bbdefb !important;
    color: #4ea0f2 !important;
    border-radius: 0 !important;
  }
  
  .react-aria-CalendarCell[data-selected]:hover {
    background: #24b8e5;
  }
  
  .react-aria-CalendarCell[data-selection-middle]:hover {
    background: #90caf9 !important;
  }
  
  .react-aria-CalendarCell[data-selection-start]:hover,
  .react-aria-CalendarCell[data-selection-end]:hover {
    background: #1565c0 !important;
  }
  
  .react-aria-CalendarCell[data-today] {
    font-weight: bold;
  }
  
  .react-aria-CalendarCell[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .react-aria-CalendarGrid {
    border-collapse: collapse;
    width: 100%;
  }
  
  .react-aria-CalendarCell[role="columnheader"] {
    font-weight: 600;
    color: #666;
    padding: 8px;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .react-aria-Group {
      min-width: 100%;
      padding: 6px 8px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  
    [slot=start], [slot=end] {
      width: 100%;
      margin-right: 0;
    }
  
    .react-aria-DateInput {
      width: 100%;
    }
  
    .react-aria-Popover[data-trigger=DateRangePicker] {
      position: fixed;
      left: 50% !important;
      right: auto !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      margin: 8px;
      max-height: 90vh;
      overflow-y: auto;
      width: calc(100% - 16px);
      max-width: 400px;
    }
  
    .react-aria-RangeCalendar {
      padding: 8px;
      min-width: unset;
    }
  
    .react-aria-CalendarCell {
      padding: 4px;
      font-size: 14px;
    }
  
    .react-aria-CalendarCell[role="columnheader"] {
      padding: 4px;
      font-size: 12px;
    }
  
    .react-aria-Heading {
      font-size: 14px;
    }
  
    .calendar-header {
      padding: 4px 8px;
      margin-bottom: 8px;
    }
  
    .calendar-nav-button {
      width: 28px;
      height: 28px;
      font-size: 16px;
    }
  }
  
  /* Add styles for even smaller screens */
  @media (max-width: 360px) {
    .react-aria-CalendarCell {
      padding: 2px;
      font-size: 12px;
    }
  
    .react-aria-RangeCalendar {
      padding: 4px;
    }
  
    .react-aria-Popover[data-trigger=DateRangePicker] {
      margin: 4px;
      width: calc(100% - 8px);
    }
  }
  
  /* Make calendar header more visible */
  .react-aria-RangeCalendar .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    margin-bottom: 16px;
    width: 100%;
  }
  
  .react-aria-RangeCalendar .calendar-nav-button {
    background: transparent;
    color: #1976d2;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .react-aria-RangeCalendar .calendar-nav-button:hover {
    background: rgba(25, 118, 210, 0.04);
  }
  
  .react-aria-RangeCalendar .calendar-heading {
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    margin: 0 16px;
  }
  
  .react-aria-RangeCalendar {
    width: 100%;
    min-width: 280px;
    padding: 16px;
  }
  
  /* Calendar navigation buttons */
  .react-aria-Button[slot="previous"],
  .react-aria-Button[slot="next"] {
    background: transparent;
    color: #1976d2;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 4px;
    transition: background-color 0.2s;
    cursor: pointer;
  }
  
  .react-aria-Button[slot="previous"]:hover,
  .react-aria-Button[slot="next"]:hover {
    background: rgba(25, 118, 210, 0.04);
  }
  
  /* Ensure arrows are centered and properly sized */
  .react-aria-Button[slot="previous"]::before,
  .react-aria-Button[slot="next"]::before {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Calendar header layout */
  .react-aria-RangeCalendar header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;
  }
  
  .react-aria-Heading {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    flex: 1;
    text-align: center;
  }
 