#google-maps-loader {
  display: none;
}

#legend {
  position: absolute;
  bottom: 25px;
  left: 10px;
  background-color: white;
  border-radius: 15px;
  padding: 5px 0.5px;
  min-width: 180px;
}

#google-maps-autocomplete-container{
  display: flex;
  align-items: center;
  position: absolute;
  left: 30%;
  margin-top: 10px;

}

#google-maps-autocomplete-input{
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 38px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}

#google-maps-plots-cumulative-area{
  display: none;
  position: absolute;
  bottom: 35px;
  left: 36.5%;
  padding: 5px 10px;
  font-size: 10px;
  background-color: rgba(19, 19, 19, 0.7);
  color: rgb(232, 232, 232);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 5px rgb(100, 100, 100);
  box-shadow: 0 0 5px rgb(100, 100, 100);
}

#google-maps-selected-region-info-container{
  display: none;
  position: absolute;
  top: 10%;
  left: 10px;
  min-height: 30px;
  background-color: rgba(19, 19, 19, 0.7);
  color: rgb(232, 232, 232);
  padding: 5px 5px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 5px rgb(100, 100, 100);
  box-shadow: 0 0 5px rgb(100, 100, 100);
}

#google-maps-selected-region-info-container > p {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

#google-maps-selected-region-info-container > div > span {
  display: flex;
}

.selected-region-text {
  font-size: 14px;
}

#google-maps-date-overlay{
  display: none;
  position: absolute;
  top: 10%;
  width: max-content;
  right: 0.7rem;
  background-color: rgba(19, 19, 19, 0.7);
  color: rgb(232, 232, 232);
  padding: 9px 5px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 5px rgb(100, 100, 100);
  box-shadow: 0 0 5px rgb(100, 100, 100);
  font-weight: 700;
  font-size: 15px;
}

#google-maps-no-data{
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(19, 19, 19, 0.7);
  color: rgb(232, 232, 232);
  /* padding: 11px 5px; */
  border-radius: 4px;
  -webkit-box-shadow: 0 0 5px rgb(100, 100, 100);
  box-shadow: 0 0 5px rgb(100, 100, 100);
  font-weight: bold;
  font-size: 15px;
  justify-content: center;
  align-items: center;
}

.gm-style .gm-style-iw {
  background-color: rgba(48, 48, 48, 0.88) !important;
  top: 0 !important;
  left: 0 !important; 
  padding: 10px;
  display: block !important;
  border-radius: 10px;
}    

.gm-style div div div div div div div div {
  margin: 0;
  padding: 0;
  top: 0;
  color: #fff;
  font-size: 16px;
}

.gm-ui-hover-effect>span {
  background-color: #fff;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: transparent;
}
.gm-style .gm-style-iw-tc::after {
  background: rgba(48, 48, 48, 0.88) !important;
}