.scales-table {
	margin: 10px 0px;
}

.scales-table th {
	padding: 5px;
	font-weight: 600;
	font-size: 12px;
	text-align: left;
}

.scales-table input {
	padding: 5px 10px;
	border-radius: 5px;
	border: 0.9px solid darkgray;
	width: 80%;
	margin: auto;
	font-size: 14px;
	height: 25px;
}

.scales-table td {
	font-size: 15px;
}